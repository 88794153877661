import React from 'react';
import Leads from '../../Leads';
import FadeIn from '../../FadeIn';
import './ContactFormBlock.scss';

export const ContactFormBlock = (props) => {
  const {
    title,
    subtext
  } = props;
  return (
    <section className="contact-form-block">
      <div className="wrap">
        <FadeIn className="contact-form-block-content">
          <h2 className="title"><strong>{title}</strong></h2>
          <p className="subtext">{subtext}</p>
        </FadeIn>
        <Leads id={5} />
      </div>
    </section>
  )
}
