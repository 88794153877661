import React from 'react';
import './StandardContent.scss';

export const StandardContent = (props) => {
  const {
    smallerWrap,
    content
  } = props;
  return (
    <section className="standard-content">
      <div className={`wrap${smallerWrap ? ' small' : ''}`} dangerouslySetInnerHTML={{__html: content}} />
    </section>
  )
}
