import React from 'react';
import { Link } from 'gatsby';
import { ImagePass } from '../../Image/ImagePass';
import Arrow from '../../Svg/Arrow';
import { Carousel } from '../../Carousel';
import FadeIn from '../../FadeIn';
import './PageLinkSlider.scss';

export const PageLinkSlider = (props) => {
  const {
    titleBold,
    titleRegular,
    subContent,
    link,
    pageLinks
  } = props;
  const settings = {
    arrows: true,
    autoplay: false,
    pauseOnHover: false,
    dots: true,
    infinite: false,
    slidesToShow: 4.2,
    className: "page-link-slider-carousel",
    responsive: [
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 3.2
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2.2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1.2
        }
      }
    ]
  };
  return (
    <section className="page-link-slider">
      <div className="wrap">
        <div className="top">
          <FadeIn className="left-section">
            <h2><strong>{titleBold}</strong> {titleRegular}</h2>
            <p className="sub-content">{subContent}</p>
          </FadeIn>
          <div className="right-section">
            {link && <Link to={link.url} className="button-link">{link.title}</Link>}
          </div>
        </div>
        <Carousel {...settings}>
          {pageLinks.map(pageLink => {
            const { title, subtext, link: innerLink, image } = pageLink;
            return (
              <div className="page-link-slide">
                <ImagePass src={image} />
                <div className="page-link-slide-content">
                  <span className="page-link-slide-title">{title}</span>
                  <p className="page-link-slide-subtext">{subtext}</p>
                  {innerLink && <Link to={innerLink.url} className="link"><Arrow className="arrow" />{innerLink.title}</Link>}
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  )
}
