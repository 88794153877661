import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GoogleMap } from '../../RenderShortcode/Shortcodes/GoogleMap';
import { decodeEntities } from '../../../utils/helpers';
import FadeIn from '../../FadeIn';
import './LocationBlock.scss';

export const LocationBlock = (props) => {
  const { siteSettings } = useStaticQuery(graphql`
    query {
      siteSettings: wordpressAcfOptions(options: {}) {
        options {
          locations {
            addressStreet
            addressCity
            addressRegion
            addressPostCode
            email
            phone
            locationName
            googleMapLink
            googleMap
          }
        }
      }
    }
  `);
  return (
    <section className="location-block">
      {siteSettings.options.locations.map(location => {
        const { locationName, addressStreet, addressCity, addressRegion, addressPostCode, email, phone, googleMapLink, googleMap } = location;
        return (
          <div className="wrap">
            <div className="location-block-contact-details">
              <FadeIn className="details">
                <h2>{locationName ? decodeEntities(locationName) : 'Main Office'}</h2>
                <div className="address"><span>{decodeEntities(addressStreet)}</span><span>{decodeEntities(addressCity)} {decodeEntities(addressRegion)} {decodeEntities(addressPostCode)}</span></div>
                {email && <div className="email"><a href={`mailto:${decodeEntities(email)}`}>{decodeEntities(email)}</a></div>}
                {phone && <div className="phone"><a href={`tel:${phone}`}>{decodeEntities(phone)}</a></div>}
                {googleMapLink && <a href={googleMapLink} target="_blank" rel="noopener noreferrer" className="button-link">View on maps</a>}
              </FadeIn>
            </div>
            <GoogleMap src={googleMap} />
          </div>
        );
      })}
    </section>
  )
}
