import React from 'react';
import { Link } from 'gatsby';
import { ImagePass } from '../../Image/ImagePass';
import Arrow from '../../Svg/Arrow';
import FadeIn from '../../FadeIn';
import './PageLinks.scss';

export const PageLinks = (props) => {
  const {
    titleBold,
    titleRegular,
    pageLinks,
  } = props;
  return (
    <section className="page-links">
      <div className="wrap">
        <FadeIn wrappingElement="h2"><strong>{titleBold}</strong> {titleRegular}</FadeIn>
        <div className="page-link-container">
          {pageLinks.map(page => {
            const { title, link, image } = page;
            return (
              <div className="page-link">
                <div className="page-link-spacer"></div>
                <ImagePass src={image} />
                <div className="page-link-content">
                  <span className="title">{title}</span>
                  {link && <Link to={link.url} className="link"><Arrow className="arrow" />{link.title}</Link>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  )
}
