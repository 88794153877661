import React from 'react';
import { ImagePass } from '../../Image/ImagePass';
import FadeIn from '../../FadeIn';
import './PageIntro.scss';

export const PageIntro = (props) => {
  const {
    pageIntroTitleBold: titleBold,
    pageIntroTitleRegular: titleRegular,
    pageIntroContent: content,
    pageIntroImage: image
  } = props;
  return (
    <section className="page-intro">
      <div className="wrap">
        <FadeIn className="page-intro-content">
          <h2><strong>{titleBold}</strong> {titleRegular}</h2>
          <div className="content" dangerouslySetInnerHTML={{__html: content}} />
        </FadeIn>
        {image && (
          <div className="page-intro-image">
            <ImagePass src={image} position="relative" />
          </div>
        )}
      </div>
    </section>
  )
}
