import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { ImagePass } from '../../Image/ImagePass';
import Arrow from '../../Svg/Arrow';
import { decodeEntities } from '../../../utils/helpers';
import './ServiceContent.scss';

export const ServiceContent = (props) => {
  const {
    titleBold,
    titleRegular,
    content,
    featuredCaseStudy,
  } = props;
  const { projects } = useStaticQuery(graphql`
    query {
      projects: allWordpressWpProjects {
        nodes {
          id: wordpress_id
          title
          link
          excerpt
          image: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          acf {
            client
          }
        }
      }
    }
  `);
  const featuredCaseStudyData = featuredCaseStudy && projects.nodes.find(n => n.id === featuredCaseStudy) !== undefined ?
    projects.nodes.find(n => n.id === featuredCaseStudy) :
    {};
  const {
    title,
    link,
    excerpt,
    image,
    acf
  } = featuredCaseStudyData;
  return (
    <section className="service-content">
      <div className="wrap">
        <div className="service-content-content">
          <h2><strong>{titleBold}</strong> {titleRegular}</h2>
          <div className="content-wrap">
            <div className="content" dangerouslySetInnerHTML={{__html: content}} />
          </div>
        </div>
        {featuredCaseStudyData && (
          <div className="featured-case-study">
            <span>Featured case study</span>
            <div className="featured-case-study-content">
              <div className="featured-case-study-image">
                <ImagePass src={image} />
              </div>
              <Link to={link} className="case-study-title"><span>{acf && acf.client ? `${decodeEntities(acf.client)} - ` : ''} {decodeEntities(title)}</span></Link>
              <div className="case-study-excerpt" dangerouslySetInnerHTML={{__html: excerpt}} />
              <Link to={link} className="case-study-link"><Arrow className="arrow" />View case study</Link>
            </div>
            <Link className="button-link" to="/case-studies/">View all case studies</Link>
          </div>
        )}
      </div>
    </section>
  )
}
