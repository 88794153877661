import React, { Component } from 'react';
import FadeIn from '../../FadeIn';
import { ImagePass } from '../../Image/ImagePass';
import { Carousel } from '../../Carousel';
import './LogoFeed.scss';

class ThisSlider extends Component {
  render () {
    const { gallery } = this.props;
    const settings = {
      infinite: true,
      dots: true,
      arrows: false,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 700,
          settings: "unslick"
        }
      ]
    };
    return (
      <div className="logo-feed-carousel">
        <Carousel {...settings}>
          {gallery && gallery.map((image, slideCount) => (
            <div key={slideCount} className="slide">
              <div className="aspect">
                <ImagePass src={image} className="logo-feedlogo" position="relative" />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}

export const LogoFeed = (props) => {
  const { LogoGallery: gallery, titleBold, titleRegular } = props;
  return (
    <section className="logo-feed">
      <div className="wrap">
        <FadeIn wrappingElement="h2"><strong>{titleBold}</strong> {titleRegular}</FadeIn>
        <ThisSlider gallery={gallery} />
      </div>
    </section>
  )
}
