import React from 'react';
import { Link } from 'gatsby';
//import FadeIn from '../../FadeIn';
//import { Carousel } from '../../Carousel';
import { ImagePass } from '../../Image/ImagePass';
import HomePageImageQuery from '../../Image/HomePageImageQuery'
import './HomepageHeader.scss';

export const HomepageHeader = (props) => {
  const {
    headingBold,
    headingRegular,
    content,
    link,
    pageScrollItems,
    location
  } = props;
  /*
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    dots: true,
    infinite: true,
    fade: true,
    className: "homepage-images-carousel",
  };
  */
  const scrollTo = (e, i) => {
    e.preventDefault();
    if (Array.from(document.querySelectorAll('section')).length >= i + 1) {
      const section = Array.from(document.querySelectorAll('section'))[i];
      window.scrollTo({ top: section.offsetTop, behavior: 'smooth' });
    }
    return false;
  }

  return (
    <section className="homepage-header">

      {location.pathname === "/" && <div className="static-background"><HomePageImageQuery className="background"/></div>}


      {/*images && location.pathname !== '/' && (
        <Carousel {...settings}>
          {images.map((image, index) => {
            return (
              <div key={index} className="homepage-header-image-container">
                {location.pathname === "/" && index == 0 ? '' : <ImagePass src={image} className="homepage-header-image" /> }
              </div>
            )
          })}
        </Carousel>
      )*/}


      <div className="homepage-header-content">

          <div className="wrap">
            <div className="content-wrapper">
              <div className="content" delay="0.5s">
                <h1><strong>{headingBold}</strong> {headingRegular}</h1>
                {content && <p dangerouslySetInnerHTML={{__html: content}} />}
                {link && <Link className="link" to={link.url}>{link.title}</Link>}
              </div>
            </div>

            <div className="page-scroll-items" delay="1s">
              <div className="inner-wrap">
                {pageScrollItems.map((item, index) => {
                  const { title, subtitle } = item;
                  return (
                    <a key={index} className="page-scroll-item" href="#" onClick={(e) => scrollTo(e, index + 1)}>
                      <span className="page-scroll-item-title">{title}</span>
                      <p>{subtitle}</p>
                    </a>
                  );
                })}
              </div>
            </div>

          </div>

      </div>
    </section>
  )
}
