import React from 'react';
import { Link } from 'gatsby';
import { ImagePass } from '../../Image/ImagePass';
import Arrow from '../../Svg/Arrow';
import FadeIn from '../../FadeIn';
import './CtaGrid.scss';

export const CtaGrid = (props) => {
  const {
    titleBold,
    titleRegular,
    gridItems,
    image
  } = props;
  return (
    <section className="cta-grid">
      <div className="cta-grid-content-wrapper">
        <div className="wrap">
          <FadeIn wrappingElement="h2"><strong>{titleBold}</strong> {titleRegular}</FadeIn>
          <div className="cta-grid-items">
            {gridItems.map((gridItem, index) => {
              const { title, content, link } = gridItem;
              return (
                <div className="cta-grid-item" key={index}>
                  <div className="cta-grid-item-content-container">
                    <span className="cta-grid-item-title">{title}</span>
                    <p className="cta-grid-item-content" dangerouslySetInnerHTML={{__html: content}} />
                  </div>
                  {link && (
                    <Link to={link.url} className="link">
                      <Arrow className="arrow" />{link.title}
                    </Link>
                  )}
                </div>
              );
            })}
            <div className="cta-grid-item">
              <FadeIn className="cta-grid-image">
                <ImagePass src={image} className="image" />
              </FadeIn>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
