import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { ImagePass } from '../../Image/ImagePass';
import { Carousel } from '../../Carousel';
import Arrow from '../../Svg/Arrow';
import FadeIn from '../../FadeIn';
import './RecentProjects.scss';

export const RecentProjects = (props) => {
  const {
    titleBold,
    titleRegular,
    content,
    link
  } = props;
  const { recentProjects } = useStaticQuery(graphql`
    query InnerRecentProjectsQuery {
      recentProjects: allWordpressWpProjects(sort: {fields: date, order: DESC}, limit: 6) {
        nodes {
          image: featured_media {
            localFile {
              childImageSharp {
                fixed(height: 600, width:576 ) {
                  ... GatsbyImageSharpFixed
                }
              }
            }
          }
          link
          title
          excerpt
        }
      }
    }
  `);
  return (
    <section className="recent-projects">
      <div className="recent-projects-background-wrapper">
        <div className="wrap">
          <FadeIn className="recent-projects-content">
            <h2><strong>{titleBold}</strong> {titleRegular}</h2>
            {content && <p dangerouslySetInnerHTML={{__html: content}} />}
            {link && <Link className="button-link" to={link.url}>{link.title}</Link>}
          </FadeIn>
          {recentProjects && (
            <div className="recent-projects-slider">
              <Carousel
                slidesToShow={2.5}
                slidesToScroll={1}
                lazyLoad={true}
                responsive={[
                  {
                    breakpoint: 1080,
                    settings: {
                      slidesToShow: 1.2
                    }
                  },
                  {
                    breakpoint: 700,
                    settings: {
                      slidesToShow: 1.1,
                      dots: false,
                      arrows: false
                    }
                  }
                ]}
              >
                {recentProjects.nodes.map((project, index )=> {
                  const { image, link: projectLink, title, excerpt } = project;
                  return (
                    <div key={index} className="recent-projects-slide">
                      <div className="recent-project-slide-image-container">
                        <ImagePass className="recent-project-slide-image" src={image} />
                      </div>
                      <div className="recent-projects-slide-content">
                        <Link to={projectLink} className="project-title"><span dangerouslySetInnerHTML={{__html: title}} /></Link>
                        <div className="excerpt" dangerouslySetInnerHTML={{__html: excerpt}} />
                        <Link to={projectLink} className="link">
                          <Arrow className="arrow" />
                          Learn more
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
