import React from 'react';
import { Link } from 'gatsby';
import { ImagePass } from '../../Image/ImagePass';
import FadeIn from '../../FadeIn';
import './ImageLeftContentRight.scss';

export const ImageLeftContentRight = (props) => {
  const {
    titleBold,
    titleRegular,
    content,
    link,
    image
  } = props;
  return (
    <section className="image-left-content-right">
      <div className="image-left-content-right-image">
        <ImagePass src={image} position="relative" />
      </div>
      <div className="wrap">
        <div className="image-left-content-right-content">
          <FadeIn className="content-wrapper">
            <h2><strong>{titleBold}</strong> {titleRegular}</h2>
            <div className="the-content" dangerouslySetInnerHTML={{__html: content}} />
            {link && <Link to={link.url} className="button-link">{link.title}</Link>}
          </FadeIn>
        </div>
      </div>
    </section>
  )
}
